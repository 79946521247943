import Vue from "vue";
import VueRouter from "vue-router";
import HomeView from "../views/HomeView.vue";

Vue.use(VueRouter);

const routes = [
    {
        path: "/",
        name: "Home",
        component: HomeView,
    },
    {
        path: "/:catchAll(.*)",
        redirect: () => {
            return {
                name: "Home",
            };
        },
    },
    // {
    //     path: "/",
    //     redirect: { name: "Home" },
    // },
];

const router = new VueRouter({
    mode: "history",
    base: process.env.BASE_URL,
    routes,
});

export default router;
