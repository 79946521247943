<template>
    <div class="home">
        <iframe
            v-if="link"
            class="video-iframe"
            width="560"
            height="315"
            :src="link"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
        ></iframe>
        <h1 v-else>please type link url</h1>
    </div>
</template>

<script>
// @ is an alias to /src

export default {
    name: "HomeView",
    components: {},
    data() {
        return {
            link: this.$route.meta.link.substring(1),
            // link: "https://www.youtube.com/embed/rIzEV-b4UJs",
            testLink: "https://www.youtube.com/embed/rIzEV-b4UJs",
        };
    },
};
</script>
<style lang="scss">
.video-iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
}
</style>
